import PropTypes from 'prop-types';
// Utils & misc
import { cn } from 'utils';
// Style
import styles from './Button.module.css';
// Components
import { Picto, availablePictos } from '../Picto/Picto';

// VIRG wanted 2 CTA types, primary and secondary so we need to create
// some kind of props combination to handle this

const CTA_TYPES = {
  primary: {
    light: {
      outline: false,
      color: 'primary',
    },
    dark: {
      outline: false,
      color: 'primary',
    },
    neutral: {
      outline: false,
      color: 'primary',
    },
  },
  secondary: {
    light: {
      outline: true,
      color: 'primary',
    },
    dark: {
      outline: true,
      color: 'primary',
    },
    neutral: {
      outline: true,
      color: 'primary',
    },
  },
};

function Button({
  onClick,
  isSubmit,
  isLoading,
  icon,
  iconPosition,
  children,
  className,
  disabled,
  color,
  outline,
  CTAType,
  usageContext,
}) {
  return (
    <button
      className={cn([
        styles.button,
        isLoading && styles.isLoading,
        (outline || CTA_TYPES?.[CTAType]?.[usageContext].outline) && styles.outline,
        className,
      ])}
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      <span className={cn([
        styles.inside,
        styles[CTA_TYPES?.[CTAType]?.[usageContext].color || color],
        styles[iconPosition]])}
      >
        {isLoading ? <Picto icon="loader" className={styles.loader} /> : null}
        {!isLoading && icon ? <Picto icon={icon} className={styles.icon} /> : null}
        {children}
      </span>
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'secondary']),
  isSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.oneOf(Object.keys(availablePictos)),
  iconPosition: PropTypes.oneOf(['left', 'right']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  CTAType: PropTypes.oneOf(['primary', 'secondary']),
  usageContext: PropTypes.oneOf(['light', 'dark', 'neutral']),
};

Button.defaultProps = {
  children: null,
  onClick: undefined,
  color: 'primary',
  isSubmit: false,
  isLoading: false,
  icon: null,
  iconPosition: 'left',
  className: null,
  disabled: false,
  outline: false,
  CTAType: null,
  usageContext: null,
};

export default Button;
